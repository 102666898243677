@import '~antd/dist/antd.css';

#root {
  display: flex;
  flex: 1;
  height: 100%;
}

.account-data .ant-card-head {
  height: 65px;
}

.account-data h4,
.account-data p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.setting {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.ant-statistic-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-input-number {
  width: 315px;
}

.dropdown-filter {
  display: inline-block;
  margin-right: 10px;
}

.dropdown-filter .ant-form-item-control-wrapper {
  display: inline-block;
}

.ant-btn {
  margin-right: 5px;
}
